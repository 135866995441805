/*
* 업무구분: 일정 추가
* 화면 명: MSPAP303P
* 화면 설명: 일정 추가
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" :title="lv_Title" @on-header-left-click="cancel">
  <!-- <ur-page-container class="msp" :show-title="true" title="일정추가" type="subpage" :topButton="false"> -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
        <!-- 구분 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="title must mb10 fwm fs18rem">구분</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="schlTab" @click="fn_ChangeSchdlTab">
              <mo-segment-button value="3">공유일정</mo-segment-button>
              <mo-segment-button value="2">개인일정</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 구분 end -->
        <!-- 일정제목 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="title must mb10 fwm fs18rem">일정제목</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-field v-model="schlTitleData" placeholder="입력하세요 (최대 16자)" maxlength="16" underline class="sch-input no-sch mt0" :disabled="isSchdlCust"/>
          </ur-box-container>
        </ur-box-container>
        <!-- 일정제목 end -->
        <!-- 대상 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box" v-if="isShareSchdl"> <!-- 공유일정일때-->
          <span class="title must mb10 fwm fs18rem">대상</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <SearchCondOrgAP ref="refSearchOrg" v-model="lv_SelectOrg" :pOrgData="pOrgData303P" :isShowCnslt="false" @selectResult="fn_SlectCnsltResultAP"/>
          </ur-box-container>
        </ur-box-container>
        <!-- 대상 end -->
        <!-- 일시 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <div class="fexTy3">
            <span class="title must">일시</span>
            <span class="crTy-bk7">하루종일<mo-switch v-model="toggle" small class="ns-switch ml7"/></span>
          </div>
          <div class="add-date">
            <mo-date-picker v-model="startYmd" placeholder="선택일" class="ns-date-picker" />
            <span class="ns-space-10"></span>
            <mo-time-picker v-if="!toggle" v-model="fcSchdlStrHm"  class="ns-time-picker" />
          </div>
          <div class="add-date mt20">
            <mo-date-picker bottom v-model="endYmd" placeholder="선택일" class="ns-date-picker" />
            <span class="ns-space-10"></span>
            <mo-time-picker v-if="!toggle" v-model="fcSchdlEndHm"  class="ns-time-picker" />
          </div>
        </ur-box-container>
        <!-- 일시 end -->

        <!-- 이미지업로드 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box" v-if="isShareSchdl">
          <span class="title mb10 fwm fs18rem">이미지업로드 (최대 2장까지 가능)</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-button color="normal" shape="border" size="small" class="blue bgcolor-2 fwm mr10" @click="fn_ImgAdd()">+첨부</mo-button>

            <div class="img-btn-delete" v-show="lv_ImgList.length > 0" @click="fn_ImgDel()"/>
            <ur-box-container v-show="lv_ImgList.length > 0" class="add-box">
              <!-- 슬라이딩 배너 영역 --> 
              <MSPCarousel ref="container1" class="w100 h100 mt10" :number-of-page="lv_ImgList.length" width="auto" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" noRotate
                            @on-change="fn_ImgChng">
                <template #1>
                  <div>
                    <img v-bind:src="lv_ImgList[0]"/>
                  </div>
                </template>
                <template #2>
                    <div>
                      <img v-bind:src="lv_ImgList[1]"/>
                    </div>
                </template>
              </MSPCarousel>
              <!-- 슬라이딩 배너 영역 //--> 
            </ur-box-container>
          </ur-box-container>
        </ur-box-container>
        <!-- 이미지업로드 end -->

        <!-- 장소 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="title mb10 fwm fs18rem">장소</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-field v-model="placeData" placeholder="입력하세요 (최대 33자)" underline class="sch-input no-sch mt0"/>
          </ur-box-container>
        </ur-box-container>
        <!-- 장소 end -->

        <!-- 분류 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box"  v-if="isShareSchdl"> <!-- 공유일정일때-->
          <span class="title must">분류</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="mngrCustCntcData" class="chip-type-wrap chip-ty1">
              <!-- <mo-segment-button value="3">콜로세움</mo-segment-button>
              <mo-segment-button value="4">JOB설명회</mo-segment-button>
              <mo-segment-button value="5">VIP세미나</mo-segment-button>
              <mo-segment-button value="6">교육</mo-segment-button>
              <mo-segment-button value="7">조회</mo-segment-button>
              <mo-segment-button value="8">기타</mo-segment-button> -->
              <template v-for="(data, i) in mngrCustCntcList" >
                <mo-segment-button :key="i" :value="data.id">{{ data.name }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 분류 end -->
        <!-- 내용 -->
        <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="add-box"  v-if="isShareSchdl"> <!-- 공유일정일때 -->
          <span class="title">내용</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-area maxlength="50" v-model="fcSchdlCntnt" class="hauto pa12" rows="5" placeholder="입력하세요 (최대 50자)" />
          </ur-box-container>
        </ur-box-container>
        <!-- 내용 end -->
        <!-- 휴대폰알림 -->
        <ur-box-container alignV="start" componentid="ur_box_container_015" direction="column" class="add-box mb50">
          <span class="title">휴대폰알림</span>
            <div class="alarm-box">
              <mo-checkbox v-model="isPushYn" class="ns-check">사용</mo-checkbox>
              <template v-if="isPushYn">
                <msp-bottom-select :items="mobileAlarmList" :itemValue="'value'" :itemText="'text'" v-model="fcIapTmScCdsKeys" underline class="ns-dropdown-sheet" bottom-title="휴대폰알림" closeBtn scrolling placeholder="선택" />
                <mo-time-picker v-model="fcIapHm" class="ns-time-picker" />
              </template>
            </div>
            <template v-if="isPushYn && isShareSchdl">
              <div class="text-gray-box mt20">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li>소속된 지역단 또는 지점의 사랑On 설치자에게 푸시알림이 갑니다.</li>
                  <li>휴대폰 알림 설정 시, 알림 10분전에는 알림취소/일정삭제가 불가합니다.</li>
                </ul>
              </div>
            </template>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="cancel">취소</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="validationYn" @click.stop="fn_validation">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import SearchCondOrgAP from '@/ui/ap/SearchCondOrgAP.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import apConstants from '@/config/constants/apConstants'

  import MSPCarousel from '@/ui/_cmm/components/MSPCarousel'

  import testImg from '@/ui/cm/test'// Local Test용 base64 Data

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP303P",
    screenId: "MSPAP303P",
    components: {
      SearchCondOrgAP,
      MSPCarousel
    },
    props: {
      pSchdlItem: {
        type: Object,
        default: {}
      },
      pChoiceYmd: {
        type: String,
        default: ''
      },
      pFcSchdlSno: {
        type: Number,
        default: 0
      },
      pMobslChnlCustId: {
        type: String,
        default: ' '
      },
      pCustAlctnCmpgnId: {
        type: String,
        default: ''
      },
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_OnLoad()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.fcIapHm = this.fcSchdlStrHm
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isInValid: false, // 밸리데이션용
        inValidMsg: '', // 밸리데이션 체크시 메세지
        lv_Title: '', // 팝업 타이틀

        isSchdlCust: false, // 고객일정 여부
        
        toggle: false, // 종일여부
        startYmd: '', // 일정 시작일시
        endYmd: '', // 일정 종료일시
        reqFirst: false, // 중복 호출 방지를 위해
        fcSchdlStrHm: this.fn_SetTime(moment(new Date()).add(1, 'hour').format('hh:00 A')), // 일정 시작 시간
        fcSchdlEndHm: this.fn_SetTime(moment(new Date()).add(2, 'hour').format('hh:00 A')), // 일정 종료 시간
        mngrCustCntcList: [], // 분류 목록
        mngrCustCntcData: '', // 분류 선택
        schlTitleData:'', // 일정 제목
        isPushYn: false, // 휴대폰알림 여부
        schlTab: '3', // 구분 탭
        fcIapTmScCdsKeys: '1', // 휴대폰알람 사용시 알람시점
        fcIapHm: ' ', // 휴대폰알람 사용시 알람시간
        placeData: '', // 장소
        validationYn: true, // 확인버튼 활성화 여부
        fcSchdlCntnt: '', // 공유 내용 데이터
        schdlInfoVO: { // I/F(fn_InsSchdlInfo)로 보낼 정보 모음.
          fcSchdlSno: 0,
          fcSchdlScCd: ' ', // FC일정 일련번호
          mobslChnlCustId: ' ', // 모바일 영업채널고객ID
          fcSchdlNm: ' ',
          fcSchdlStrYmd: ' ',
          fcSchdlStrHm: ' ',
          fcSchdlEndYmd: ' ',
          fcSchdlEndHm: ' ',
          fcSchdlPrmsPlcNm: ' ',
          custAlctnCmpgnId: ' ', // 고객배정캠페인ID
          cntcObjCmpgnNm: ' ',
          fcIapReciYn: ' ',
          fcIapPushReqId: 0,
          fcIapReqId: 0,
          fcIapTmScCd: ' ',
          fcIapHm: ' ',
          custCntcId: ' ',
          fcSchdlCntnt: ' '
        },

        lv_SelectOrg: {}, // 선택한 조직 정보
        pOrgData303P: {}, // 바인딩할 조직 정보
        lv_ImgList: [],
        lv_ImgIdx: 0,
        isChgImgList: false,

        swiperOption: {
          // centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: false,
          /* autoplay: {
            delay: 5000,
            disableOnInteraction: false, 
          }, */
        },

      }
    },
    watch: {
      toggle() {
        if (!this.toggle) {
          let t_startTime = moment(new Date()).add(1, 'hour').format('hh:00 A')
          let t_endTime = moment(new Date()).add(2, 'hour').format('hh:00 A')
          this.fcSchdlStrHm = this.fn_SetTime(t_startTime)
          this.fcSchdlEndHm = this.fn_SetTime(t_endTime)
        } else {
          this.fcSchdlStrHm = ''
          this.fcSchdlEndHm = ''
        }
      },
      startYmd() {
        console.log('일정 시작일자 변경')
        console.log('비교', Number(this.startYmd.replaceAll('-', '')) > Number(this.endYmd.replaceAll('-', '')))
        if (Number(this.startYmd.replaceAll('-', '')) > Number(this.endYmd.replaceAll('-', ''))) {
          this.endYmd = this.startYmd
          console.log('this.endYmd>>', this.endYmd)
        }
      },
      endYmd() {
        console.log('일정 종료일자 변경')
        if (Number(this.startYmd.replaceAll('-', '')) > Number(this.endYmd.replaceAll('-', ''))) {
          this.startYmd = this.endYmd
          console.log('this.startYmd>>', this.startYmd)
        }
      },
      fcSchdlStrHm() {
        if ((this.startYmd === this.endYmd) && Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlStrHm)) > Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlEndHm))) {
          let t_strHm = Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlStrHm))
          if (t_strHm === 2300) {
            this.endYmd = moment(this.endYmd).add(1, 'days').format('YYYY-MM-DD')
            this.fcSchdlEndHm = this.fn_UITimeCompr('0000')
          } else {
            this.fcSchdlEndHm = this.fn_UITimeCompr(String(t_strHm + 100))
          }
        }
      },
      fcSchdlEndHm() {
        console.log('일정 종료시간 변경',this.fcSchdlEndHm)
        if ((this.startYmd === this.endYmd) && Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlStrHm)) > Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlEndHm))) {
          let t_endHm = Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlEndHm))
          if (t_endHm === 0) {
            console.log('this.startYmd>>', this.startYmd)
            this.startYmd = moment(this.startYmd).subtract(1, 'days').format('YYYY-MM-DD')
            this.fcSchdlStrHm = this.fn_UITimeCompr('2300')
          } else {
            console.log('this.t_endHm>>', t_endHm )
            this.fcSchdlStrHm = this.fn_UITimeCompr(String(t_endHm - 100))
          }
        }
      },
      schlTitleData() {
        if (this.schlTitleData.length > 0) {
          this.validationYn = false
        } else {
          this.validationYn = true
        }
      },

      schlTab() {
        // 첨부 이미지 초기화
        this.lv_ImgList = []
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      mobileAlarmList() {
        let rtn = [];
        rtn.push({
          value: '1',
          text: '당일'
        });
        rtn.push({
          value: '2',
          text: '1일전'
        });
        return rtn;
      },
      isShareSchdl() {
        let rtnValu = false
        rtnValu = this.schlTab === '3'
        return rtnValu
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_OnLoad
       * 설명: 최초 데이터 셋팅
       *********************************************************/
      fn_OnLoad() {
        this.lv_Title = this.pFcSchdlSno > 0 ? '일정수정': '일정추가'
        console.log('this.pFcSchdlSno>>>', this.pFcSchdlSno , this.lv_Title)

        if(!_.isEmpty(this.pSchdlItem)) {
          this.pOrgData303P.hofOrg = {key: this.pSchdlItem.shareHofOrgNo, label:this.pSchdlItem.shareHofOrgNoNm}
          this.pOrgData303P.dofOrg = {key: this.pSchdlItem.shareDofOrgNo, label:this.pSchdlItem.shareDofOrgNoNm}
          this.pOrgData303P.fofOrg = {key: this.pSchdlItem.shareFofOrgNo, label:this.pSchdlItem.shareFofOrgNoNm}
        }

        if (Number(this.pFcSchdlSno) > 0) { // 일정수정
          this.fn_SearchSchedule()
        } else { // 일정추가
          this.startYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD')
          this.endYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD')
        }

        this.mngrCustCntcList = apConstants.mngrCustCntc
        this.mngrCustCntcData = apConstants.mngrCustCntc[0].id
      },
      /*********************************************************
       * Function명: fn_SearchSchedule
       * 설명: 수정일 경우 데이터 조회
       *********************************************************/
      fn_SearchSchedule () {
        let lv_Vm = this
        let trnstId = 'txTSSAP20S1'

        if (!_.isEmpty(this.pSchdlItem)) { // 공유 일정 조회
          trnstId = 'txTSSAP20S2'
        }

        let pParam = {
          userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
          fcSchdlSno: Number(lv_Vm.pFcSchdlSno)
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
          .then(function (response) {
            console.log('fn_SearchSchedule response>><><><', response)
            if ( response.body != null) {
              console.log('mobslChnlCustId ' + response.body.mobslChnlCustId )
              // 공유일정은 수정불가하여 무조건 개인일정
              lv_Vm.schlTab = response.body.fcSchdlScCd // 구분탭
              lv_Vm.schlTitleData= response.body.fcSchdlNm // 일정제목
              lv_Vm.startYmd = moment(response.body.fcSchdlStrYmd).format('YYYY-MM-DD') // 시작일시
              lv_Vm.fcSchdlStrHm = response.body.fcSchdlStrHm // 시작시각
              lv_Vm.endYmd = moment(response.body.fcSchdlEndYmd).format('YYYY-MM-DD') // 종료일시
              lv_Vm.fcSchdlEndHm = response.body.fcSchdlEndHm // 종료시각
              lv_Vm.placeData = response.body.fcSchdlPrmsPlcNm // 장소
              lv_Vm.fcSchdlCntnt = response.body.fcSchdlCntnt // 내용
              lv_Vm.mngrCustCntcData = response.body.custCntcId // 분류

              if (!lv_Vm.$bizUtil.isEmpty(response.body.mobslChnlCustId)) {
                lv_Vm.isSchdlCust = true
              }
              
              if ( !lv_Vm.$bizUtil.isEmpty(response.body.fcSchdlStrHm) && 
                    !lv_Vm.$bizUtil.isEmpty(response.body.fcSchdlEndHm) ) {
                lv_Vm.fcSchdlStrHm = lv_Vm.fn_UITimeCompr(response.body.fcSchdlStrHm)
                lv_Vm.fcSchdlEndHm = lv_Vm.fn_UITimeCompr(response.body.fcSchdlEndHm)
              } else {
                lv_Vm.toggle = true
              }

              if (!_.isEmpty(lv_Vm.pSchdlItem.imageList)) {
                lv_Vm.lv_ImgList = lv_Vm.pSchdlItem.imageList
              }

              if ( response.body.fcIapReciYn === 'Y' ) {
                lv_Vm.isPushYn = true
                lv_Vm.fcIapTmScCdsKeys = response.body.fcIapTmScCd
                lv_Vm.fcIapHm = lv_Vm.fn_UITimeCompr(response.body.fcIapHm)
              } else {
                lv_Vm.isPushYn = false
              } 
            }
          })
      },
      /*********************************************************
       * Function명: fn_UITimeCompr
       * 설명: 서버에서 받아온 데이터 포맷팅
       *********************************************************/
      fn_UITimeCompr (time) {
        console.log('fn_UITimeCompr', time)
        if(time.length === 3) time = '0' + time
        if (time.length === 4) {
          let timeHm = time.substring(0, 2)
          let timeMm = time.substring(2, 4)
          if (Number(timeHm) === 0) {
            timeHm = '12'
            time = timeHm + ':' + timeMm + ' ' + 'AM'
          } else if (Number(timeHm) === 12) {
            timeHm = '12'
            time = timeHm + ':' + timeMm + ' ' + 'PM'
          } else {
            time = timeHm + ':' + timeMm
          }
          console.log('수정일시 response data  formating>>', time)
          return this.fn_SetTime( moment(time, 'h:mm A').format('hh:mm A'))
        }
      },
      /*********************************************************
       * Function명: fn_SetTime
       * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
       *********************************************************/
      fn_SetTime (time) {
        let timeA = time.substring(6, 8)
        let timeHH = time.substring(0, 2)
        let timeMM = time.substring(3, 5)
        if (timeA.trim() === 'AM') {
          timeA = '오전'
        } else {
          timeA = '오후'
        }
        let t_time = timeA + ' ' + timeHH + ':' + timeMM
        return t_time
      },
      /******************************************************************************
       * Function명 : fn_ChangeSchdlTab
       * 설명       : 공유일정/개인일정 변경 시 화면 초기화
       ******************************************************************************/
      fn_ChangeSchdlTab () {
        this.fn_ClearData()
      },
      /*********************************************************
       * Function명: fn_validation
       * 설명: 확인 버튼 클릭시 밸리데이션 체크
       *********************************************************/
      fn_validation() {
        if(this.reqFirst) return

        if (process.env.NODE_ENV === 'local' && this.isPushYn) {
          this.$addSnackbar('Local에서 알림을 설정할 수 없습니다.')
          return
        }

        // 밸리데이션 체크
        if (this.fn_ValidateBeforeSubmit()) {
          console.log('밸리데이션!!!')
          this.fn_BottomAlert(this.inValidMsg)
        } else {
          if (this.schlTab === '3') { // 공유일정
            if (this.isPushYn) {
              let content = '알림 설정 시, 알림 시간 10분전부터는 알림취소/일정삭제가 불가합니다.<br/>알림을 설정하시겠습니까?'
              this.fn_BottomConfirm(content)
            } else {
              this.fn_InsSchdlInfo()
            }
          } else { // 개인일정
            this.fn_InsSchdlInfo()
          }
        }
      },
      /*********************************************************
       * Function명: fn_InsSchdlInfo
       * 설명: 고객 정보 저장 메서드
       *********************************************************/
      fn_InsSchdlInfo() {
        let lv_Vm = this
        let trnstId = ''
        let auth = ''

        console.log('fn_InsSchdlInfo실행!!!!>>>', lv_Vm.pFcSchdlSno)
        if (Number(lv_Vm.pFcSchdlSno) > 0) {
          trnstId = 'txTSSAP20U1'
          auth = 'U'
          if (!_.isEmpty(this.pSchdlItem)) { // 공유 일정 수정
            trnstId = 'txTSSAP20U2'
          }
          lv_Vm.schdlInfoVO.fcSchdlSno = lv_Vm.pFcSchdlSno
        } else {
          trnstId = 'txTSSAP20I1'
          auth = 'I'
        }

        console.log('trnstId>>>', trnstId)
        lv_Vm.schdlInfoVO.fcSchdlScCd = lv_Vm.schlTab // 공유/개인 구분
        lv_Vm.schdlInfoVO.fcSchdlNm = lv_Vm.schlTitleData // 일정명
        lv_Vm.schdlInfoVO.fcSchdlStrYmd = lv_Vm.startYmd // 일정 시작일
        lv_Vm.schdlInfoVO.fcSchdlEndYmd = lv_Vm.endYmd // 일정 종료일
        if ( this.toggle ) { // 종일여부
          lv_Vm.schdlInfoVO.fcSchdlStrHm = ' ' // 일정 시작시간
          lv_Vm.schdlInfoVO.fcSchdlEndHm = ' ' // 일정 종료시간
        } else {
          lv_Vm.schdlInfoVO.fcSchdlStrHm = this.fn_ServerTimeCompr(lv_Vm.fcSchdlStrHm)
          lv_Vm.schdlInfoVO.fcSchdlEndHm = this.fn_ServerTimeCompr(lv_Vm.fcSchdlEndHm)
        }
        if (lv_Vm.schlTab === '3') { // 공유일시
          lv_Vm.schdlInfoVO.custCntcId = lv_Vm.mngrCustCntcData // 분류
          lv_Vm.schdlInfoVO.fcSchdlCntnt = lv_Vm.fcSchdlCntnt // 내용
          //lv_Vm.schdlInfoVO.fcSchdlCntnt = lv_Vm.fcSchdlCntnt !== '' ? lv_Vm.fcSchdlCntnt : ' ' // 내용

          // 첨부 이미지
          this.schdlInfoVO.ImgFstData = this.lv_ImgList[0] ? this.lv_ImgList[0] : ''
          this.schdlInfoVO.imgScndData = this.lv_ImgList[1] ? this.lv_ImgList[1] : ''

        } 
        if (this.isPushYn) { // 알람사용여부
          lv_Vm.schdlInfoVO.fcIapReciYn = 'Y'
          lv_Vm.schdlInfoVO.fcIapTmScCd = this.fcIapTmScCdsKeys
          lv_Vm.schdlInfoVO.fcIapHm = this.fn_ServerTimeComprfcIapHm(lv_Vm.fcIapHm)
        } else {
          lv_Vm.schdlInfoVO.fcIapReciYn = 'N'
          lv_Vm.schdlInfoVO.fcIapTmScCd = ' '
          lv_Vm.schdlInfoVO.fcIapHm = ' '
        }
        
        let pParam = {
          userEno: this.getStore('userInfo').getters.getUserInfo.userId,
          fcSchdlSno: lv_Vm.schdlInfoVO.fcSchdlSno,
          fcSchdlScCd: lv_Vm.schdlInfoVO.fcSchdlScCd,
          mobslChnlCustId: lv_Vm.schdlInfoVO.mobslChnlCustId,
          fcSchdlNm: lv_Vm.schdlInfoVO.fcSchdlNm,
          fcSchdlStrYmd: lv_Vm.schdlInfoVO.fcSchdlStrYmd.substring(0, 10).replace(/-/gi, ''),
          fcSchdlStrHm: lv_Vm.schdlInfoVO.fcSchdlStrHm,
          fcSchdlEndYmd: lv_Vm.schdlInfoVO.fcSchdlEndYmd.substring(0, 10).replace(/-/gi, ''),
          fcSchdlEndHm: lv_Vm.schdlInfoVO.fcSchdlEndHm,
          fcSchdlPrmsPlcNm: lv_Vm.placeData,
          custAlctnCmpgnId: lv_Vm.schdlInfoVO.custAlctnCmpgnId,
          cntcObjCmpgnNm: lv_Vm.schdlInfoVO.cntcObjCmpgnNm,
          fcIapReciYn: lv_Vm.schdlInfoVO.fcIapReciYn,
          fcIapTmScCd: lv_Vm.schdlInfoVO.fcIapTmScCd,
          fcIapPushReqId: lv_Vm.schdlInfoVO.fcIapPushReqId,
          fcIapReqId: lv_Vm.schdlInfoVO.fcIapReqId,
          fcIapHm: lv_Vm.schdlInfoVO.fcIapHm,
          custCntcId: lv_Vm.schdlInfoVO.custCntcId,
          fcSchdlCntnt: lv_Vm.schdlInfoVO.fcSchdlCntnt,
          ImgFstData: lv_Vm.schdlInfoVO.ImgFstData,
          imgScndData: lv_Vm.schdlInfoVO.imgScndData
        }

        if (!this.$bizUtil.isEmpty(lv_Vm.schdlInfoVO.custCntcId)) {
          pParam.CustCntcNm = lv_Vm.mngrCustCntcList.filter(item => 
                                  item.id === lv_Vm.schdlInfoVO.custCntcId
                                )[0].name
        }

        if (!_.isEmpty(lv_Vm.lv_SelectOrg)) {
          pParam.shareHofOrgNo = lv_Vm.lv_SelectOrg.hofOrg.key
          pParam.shareHofOrgNm = lv_Vm.lv_SelectOrg.hofOrg.label
          pParam.shareDofOrgNo = lv_Vm.lv_SelectOrg.dofOrg.key
          pParam.shareDofOrgNm = lv_Vm.lv_SelectOrg.dofOrg.label
          pParam.shareFofOrgNo = lv_Vm.lv_SelectOrg.fofOrg.key
          pParam.shareFofOrgNm = lv_Vm.lv_SelectOrg.fofOrg.label
        }

        this.reqFirst = true
        lv_Vm.post(lv_Vm, pParam, trnstId, auth)
          .then(function (response) {
            if (lv_Vm.DEBUG) console.log(response)
            if (response.msgComm.msgCd === 'ECOU002' || response.msgComm.msgCd === 'ECOU003' ) {

              let schdlNm = lv_Vm.schdlInfoVO.fcSchdlScCd === '3' ? '공유' : '개인'

              if (trnstId === 'txTSSAP20U1' || trnstId === 'txTSSAP20U2') {
                lv_Vm.getStore('toast').dispatch('ADD', schdlNm + '일정이 수정되었습니다.')
                pParam.fcSchdlSno = response.body.fcSchdlSno
              } else {
                lv_Vm.getStore('toast').dispatch('ADD', schdlNm + '일정이 추가되었습니다.')
              }

              lv_Vm.$emit('fn_OnRefresh', auth, pParam) // fn_OnRefresh
              lv_Vm.fn_CloseScreen()
            } else {
              lv_Vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
            lv_Vm.reqFirst = false
          }).catch(function (error) {
            window.vue.error(error)
            lv_Vm.reqFirst = false
          })
      },
      /*********************************************************
       * Function명: fn_ServerTimeCompr
       * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_ServerTimeCompr (time) {
        let timeA, timeHm, timeMm
          timeA = time.substring(0, 2)
          timeHm = time.substring(3, 5)
          timeMm = time.substring(6, 8)
        if (timeA === '오전') {
          if (Number(timeHm) === 12) {
            time = '00' + timeMm
          } else {
            time = timeHm + timeMm
          }
        } else {
          if (Number(timeHm) === 12) {
            time = timeHm + timeMm
          } else {
            time = Number(timeHm) + 12 + timeMm
          }
        }
        console.log('fn_ServerTimeCompr', time)
        return time
      },
      /*********************************************************
       * Function명: fn_ServerTimeComprfcIapHm
       * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_ServerTimeComprfcIapHm (time) {
        let timeA, timeB, timeHm, timeMm
          timeA = time.substring(5, 8)
          timeB = time.substring(0, 2)
          if (timeA === '오전' || timeA === '오후') {
            timeHm = time.substring(0, 2)
            timeMm = time.substring(3, 5)
            if (timeA === '오전') {
              if (Number(timeHm) === 12) {
                time = '00' + timeMm
              } else {
                time = timeHm + timeMm
              }
            } else {
              if (Number(timeHm) === 12) {
                time = timeHm + timeMm
              } else {
                time = Number(timeHm) + 12 + timeMm
              }
            }
          } else {
            timeHm = time.substring(3, 5)
            timeMm = time.substring(6, 8)
            if (timeB === '오전') {
              if (Number(timeHm) === 12) {
                time = '00' + timeMm
              } else {
                time = timeHm + timeMm
              }
            } else {
              if (Number(timeHm) === 12) {
                time = timeHm + timeMm
              } else {
                time = Number(timeHm) + 12 + timeMm
              }
            }
          }
        console.log('fn_ServerTimeComprfcIapHm', time)
        return time
      },
      /******************************************************************************
       * @function  fn_SlectCnsltResultCM()
       * @notice    조직 변경시, 전역변수에 셋팅
       *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
       * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
       * allChk: 조회버튼 활성화/비활성화 여부
       ******************************************************************************/
      fn_SlectCnsltResultAP (pData) {
        this.lv_SelectOrg = pData
      },
      /******************************************************************************
       * Function명 : fn_BottomAlert
       * 설명       : 알림 팝업 호출
       ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: contents,
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_BottomConfirm
       * 설명       : 확인/취소 팝업 호출
       ******************************************************************************/
      fn_BottomConfirm (contents) {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: contents,
          title_neg_btn:'아니오',
          title_pos_btn: '예'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_InsSchdlInfo()
            this.$bottomModal.close(this.confirmPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_CloseScreen
       * 설명       : 취소버튼 선택 시 호출  - 디비전 닫는 이벤트 emit
       ******************************************************************************/
      fn_CloseScreen () { 
        this.fn_ClearData()
      },
      /******************************************************************************
       * Function명 : fn_ClearData
       * 설명       : 초기화
       ******************************************************************************/
      fn_ClearData() {
        this.toggle = false // 종일여부
        this.startYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD') // 일정시작일
        this.endYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD') // 일정종료일
        this.fcSchdlStrHm = this.fn_SetTime(moment(new Date()).add(1, 'hour').format('hh:00 A')) // 일정시작시간
        this.fcSchdlEndHm = this.fn_SetTime(moment(new Date()).add(2, 'hour').format('hh:00 A')) // 일정종료시간
        this.schlTitleData = '' // 일정명

        this.placeData = ''     // 장소
        this.mngrCustCntcData = apConstants.mngrCustCntc[0].id  // 분류
        this.fcSchdlCntnt = '' // 내용
        this.isPushYn = false  // 알림 여부
        this.fcIapTmScCdsKeys = '1' //알림 시점
        this.fcIapHm = this.fn_SetTime(moment(new Date()).add(1, 'hour').format('hh:00 A')) // 알림 시간
      },
      /*********************************************************
       * Function명: fn_ValidateBeforeSubmit
       * 설명: 고객 정보 수정 전 Validation 함수
       * Return: isInValid ( 오류 여부 true/false)
       *********************************************************/
      fn_ValidateBeforeSubmit () {
        this.isInValid = false
        this.inValidMsg = ''
        if ( !this.toggle ) { //  종일X
          let strTime = this.fcSchdlStrHm.trim()
          let endTime = this.fcSchdlEndHm.trim()
          strTime = this.fn_ServerTimeCompr(this.fcSchdlStrHm)
          endTime = this.fn_ServerTimeCompr(this.fcSchdlEndHm)
          let strHm, strMm, endHm, endMm
          if (strTime.length < 8) {
            strHm = strTime.substring(0, 2)
            strMm = strTime.substring(2, 4)
          } else if (strTime.length >7) {
            strHm = strTime.substring(0, 2)
            strMm = strTime.substring(2, 4)
          } 
          if (endTime.length < 8) {
            endHm = endTime.substring(0, 2)
            endMm = endTime.substring(2, 4)
          } else if (endTime.length > 7) {
            endHm = endTime.substring(0, 2)
            endMm = endTime.substring(2, 4)
          }
          if ( strHm === '' || strMm === '' ) {
            this.isInValid = true
            this.inValidMsg = '시작일시을 입력하세요.'
            return this.isInValid
          } else if( endHm === '' || endMm === '' ) {
            this.isInValid = true
            this.inValidMsg = '종료일시을 입력하세요.'
            return this.isInValid
          }
          if ( Number(this.startYmd.substring(0, 10).replace(/-/gi, '')) > Number(this.endYmd.substring(0, 10).replace(/-/gi, '')) ) {
            this.isInValid = true
            this.inValidMsg = '종료일자를 시작일자\n 이후로 설정해주세요.'
            return this.isInValid
          }
          if ( this.startYmd.substring(0, 10) === this.endYmd.substring(0, 10) ) {
            if ( Number(strHm) > Number(endHm)) {
              this.isInValid = true
              this.inValidMsg = '종료일시를 시작일시\n 이후로 설정해주세요.'
              return this.isInValid
            }
          }
        } else {
          if ( Number(this.startYmd.substring(0, 10).replace(/-/gi, '')) > Number(this.endYmd.substring(0, 10).replace(/-/gi, '')) ) {
            this.isInValid = true
            this.inValidMsg = '종료일자를 시작일자\n 이후로 설정해주세요.'
            return this.isInValid
          }
        }
        // 휴대폰알림에 대한 체크
        if ( this.isPushYn ) {
          let time, timeA, timeHm, timeMm, strA, strHm, strMm
          console.log(this.fcIapHm)
          timeA = this.fcIapHm.substring(0, 2)
          timeHm = this.fcIapHm.substring(3, 5)
          timeMm = this.fcIapHm.substring(6, 8)
          console.log('timeHm', timeHm)
          console.log('timeMm', timeMm)
          console.log('timeA', timeA)
          strA = this.fcSchdlStrHm.substring(6, 8)
          strHm = this.fcSchdlStrHm.substring(3, 5)
          strMm = this.fcSchdlStrHm.substring(0, 2)
          time = this.fn_ServerTimeComprfcIapHm(this.fcIapHm)
          if ( timeA === '' || timeHm === '' || timeMm === '' ) {
            this.isInValid = true
            this.inValidMsg = '휴대폰 알림시각을 입력해주세요.'
            return this.isInValid
          }
          let nowDt = this.$bizUtil.getDateToString(new Date(), '') + this.$bizUtil.getTimeToString(new Date(), '').substring(0, 4)
          let iapDt = this.startYmd.substring(0, 10).replace(/-/gi, '') + time
          console.log('확인11', nowDt , '[]', iapDt)
          if ( Number(nowDt) > Number(iapDt) ) {
            this.isInValid = true
            this.inValidMsg = '현재 시간 이전으로\n 휴대폰알림을 등록할 수 없습니다.'
            return this.isInValid
          }
          let currentDate = this.$bizUtil.getDateToString(new Date(), '')
          let startDate = this.startYmd.substring(0, 10).replace(/-/gi, '')
          console.log('확인22', currentDate , '[]', startDate)
          if ( Number(startDate) === Number(currentDate) && this.fcIapTmScCdsKeys === '2') {
            this.isInValid = true
            this.inValidMsg = '현재 일자 이전으로\n 휴대폰알림을 등록할 수 없습니다.'
            return this.isInValid
          }
        }

        let selectHof = this.lv_SelectOrg.hofOrg // 선택한 사업부 조직
        let selectDof = this.lv_SelectOrg.dofOrg // 선택한 사업부 조직
        // (수도권/동부/서부 사업부인 경우, 저장가능)
        if(this.schlTab === '3') {
          if(selectHof.key !== "00000320" && selectHof.key !== "00305000" && selectHof.key !== "00308000") {
            if(selectDof.key === "0" || this.$bizUtil.isEmpty(selectDof.key)) {
              this.isInValid = true
              this.inValidMsg = '지역단 단위까지 설정해주세요.'
              return this.isInValid
            }
          }
        }
        return this.isInValid
      },
      cancel() {
        this.$emit('onPopupClose')
      },

      /******************************************************************************
       * Function명 : fn_ImgAdd
       * 설명       : 이미지 첨부
       ******************************************************************************/
      fn_ImgAdd() {
        const lv_Vm = this

        console.log('galleryPlugin Call!')

        if (process.env.NODE_ENV === 'local') {
          if (lv_Vm.lv_ImgList.length > 1) {
            this.fn_BottomAlert("최대 2장까지 가능합니다.")
            return
          }

          let imgData = 'data:image/jpg;base64,' + testImg.license_c
          lv_Vm.lv_ImgList.push(imgData)
          return
        }

        if (lv_Vm.lv_ImgList.length > 1) {
          this.fn_BottomAlert("최대 2장까지 가능합니다.")
          return
        }

        window.fdpbridge.exec('galleryPlugin', 
        {},(result) => {
          console.log('success: '+ JSON.stringify(result));
          
          if (result.isSuccessful) {
            if (result.data !== 'cancel' && result.data !== 'Cancel') { // And: 'cancel' / IOS: 'Cancel'
              lv_Vm.isChgImgList = true
              let base64Data = 'data:image/jpg;base64,' + result.data
              lv_Vm.lv_ImgList.push(base64Data)
            }
          } else {
            this.$addSnackbar('잠시후 다시 시도해 주세요.')
          }
        }, (err) => { 
          console.log('error: '+ JSON.stringify(err));
          this.$addSnackbar('오류가 발생했습니다.')
        });
      },
      
      /******************************************************************************
       * Function명 : fn_ImgDel
       * 설명       : 이미지 제거
       ******************************************************************************/
      fn_ImgDel() {
        console.log('image select cancel!')
        this.lv_ImgList.pop(this.lv_ImgIdx)
        this.isChgImgList = true
      },

      /******************************************************************************
       * Function명 : fn_ImgChng
       * 설명       : 이미지 슬라이드 이벤트
       ******************************************************************************/
      fn_ImgChng(idx) {
        console.log('fn_ImgChange!!', idx)
        this.lv_ImgIdx = idx
      }

    } // method end
  };
</script>
<style scoped>
  .img-btn-delete {
    position: absolute; 
    width: 20px;
    height: 20px;
    right: 40px;
    background:url(/images/btn_close_light.png)  no-repeat center / 85%; 
  }
</style>