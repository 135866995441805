/*
* 
*/


<template>
    <swiper ref="moCarouselSwiper" class="swiper" :options="cSwiperOption" :style="wrapperStyle" @slide-change="swiperSlideChng">
        <swiper-slide v-for="(item,idx) in itemCount" :key="idx">
            <div class="mo-carousel-swiper-slide" @click="fn_ClickEvent"><!--
                $ver: v0.0.1
                carousel에 구성되는 컨텐츠 영역입니다.
                #A content area showing in carousel.
                -->
                <slot :name="item"></slot>
            </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div v-if="prevButton" class="swiper-button-prev" slot="button-prev"></div>
        <div v-if="nextButton" class="swiper-button-next" slot="button-next"></div>
    </swiper>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

const DEFAULT_SWIPER_OPTION = {
    slidesPerView: 1,
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
}

export default {
    name: 'moCarousel',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        // $ver: 2.2.0
        // carousel에 보여질 페이지 개수
        // #The number of pages on carousel
        // $default: 1
        numberOfPage: {
            type: [Number, String],
            default: 1,
        },
        // $ver: v0.0.1
        // 높이를 지정합니다. 단위는 px 입니다.
        // #Specify the height. The unit is px.
        // $default: 210
        height: {
            type: [Number, String],
            default: 210,
        },
        // $ver: v0.0.1
        // 마지막 페이지에서 다음 버튼으로 첫 페이지로 전환되지 않도록 합니다.
        // #Prevents you from switching to the first page from the last page with the Next button.
        // $default: false
        noRotate  : {type:Boolean,default:false},
        // $ver: v2.2.0
        // 이전 버튼 표시 여부입니다.
        // #Show previous button.
        // $default: true
        prevButton: {type:Boolean,default:true},
        // $ver: v2.2.0
        // 다음 버튼 표시 여부입니다.
        // #Show next button.
        // $default: true
        nextButton: {type:Boolean,default:true},
        // $ver: v2.2.3
        // $hide
        // swiper에 전달할 props 객체입니다.
        // #The props objert passed to the swiper.
        // $default: {}
        swiperOption: {type:Object,default:()=>{return {}}},
    },
    data() {
        return {
            itemCount: [],
        }
    },
    watch: {
        numberOfPage() {
            console.log('watch numberOfPage!!', this.numberOfPage)
            
            this.itemCount = []
            for (let i = 1; i <= this.numberOfPage; i++) {
                this.itemCount.push(i)
            }
        }
    },
    computed: {
        cSwiperOption() {
            const rtn = {};
            Object.assign(rtn, DEFAULT_SWIPER_OPTION);
            Object.assign(rtn, this.swiperOption);
            Object.assign(rtn, {loop:!this.noRotate});
            return rtn;
        },
        wrapperStyle() {
            return {
                'height': this.height + 'px',
            }
        },
    },
    created() {
        console.log('moCarousel!! created')

        for (let i = 1; i <= this.numberOfPage; i++) {
            this.itemCount.push(i)
        }
    },
    mounted () {
        this.$refs.moCarouselSwiper.$el.addEventListener("touchstart", this.preventTouch);
    },
    beforeDestroy() {
        this.$refs.moCarouselSwiper.$el.removeEventListener("touchstart", this.preventTouch);
    },
    methods: {
        // @vuese
        // $ver: v2.2.0
        // $arg: Number - page number to move
        // $return: N/A
        // 지정한 페이지로 보여지는 컨텐츠가 바뀝니다.
        // #The content shown on the specified page changes.
        moveTo(idx){
            this.$refs.moCarouselSwiper.$swiper.slideTo(idx, 0);
        },
        // @vuese
        // $ver: v2.2.0
        // $arg: Number - page number to move, speed(default: 300ms)
        // $return: N/A
        // 지정한 페이지로 보여지는 컨텐츠가 부드럽게 바뀝니다.
        // #The content shown on the specified page changes smoothly.
        smoothMoveTo(idx, speed){
            let _speed = speed || 300;
            let _resultSpeed = _speed * Math.abs(this.$refs.moCarouselSwiper.$swiper.activeIndex - idx);

            this.$refs.moCarouselSwiper.$swiper.slideTo(idx, _resultSpeed);
        },
        preventTouch(e){
            e.stopPropagation();
        },
        swiperSlideChng () {
            // $ver: v2.2.3
            // $arg: activeIndex
            // activeIndex가 변경될 때 발생됩니다.
            // # Fired when activeIndex changes.
            this.$emit('on-change', this.$refs.moCarouselSwiper.$swiper.activeIndex);
        },
        // @vuese
        // $ver: v2.2.0
        // $arg: 
        // $return: $swiper 객체
        // mo-carousel 이 감싸고 있는 vue-awesome-swiper의 $swiper 객체를 반환합니다.
        // #Returns the #swiper object of the vue-awesome-swiper that the carousel wraps;
        getSwiper(){
            return this.$refs.moCarouselSwiper.$swiper;
        },


        fn_ClickEvent() {
            this.$emit('on-click', this.$refs.moCarouselSwiper.$swiper.activeIndex);
        }
    },
}
</script>